/* eslint-disable import/prefer-default-export */
export const ADDVANTAGESDATA = {
  ua: [
    {
      number: '17+',
      text: 'Років досвіду',
    },
    {
      number: '9',
      text: 'Представництв',
    },
    {
      number: '20000+',
      text: 'Задоволених клієнтів',
    },
    {
      number: '',
      text: '',
    },
  ],
  ru: [
    {
      number: '17+',
      text: 'Лет опыта',
    },
    {
      number: '9',
      text: 'Представительств',
    },
    {
      number: '20000+',
      text: 'Довольных клиентов',
    },
    {
      number: '',
      text: '',
    },
  ],
};
